import * as React from "react";
import { toast } from "react-toastify";
import { useState } from "react";

import { Dropdown } from "antd";
import styled from "styled-components";
import { COLORS } from "../utils/colors";

import { TabMenu } from "./common";
import { Menu, Spin } from "antd";
import { ResponseType } from "../utils/executor";
import { LoadingOutlined } from "@ant-design/icons";
import ReactResizeDetector from "react-resize-detector";
import AceEditor from "react-ace";
import { motion } from "framer-motion";
import copy from "copy-to-clipboard";

import "ace-builds/src-noconflict/ext-searchbox";

import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/theme-twilight";
import {
  ReasonPhrases,
  StatusCodes,
  getReasonPhrase,
  getStatusCode,
} from "http-status-codes";
import { sizes } from "../utils/sizes";
import { DownArrow, DownArrowSmall } from "./icons";
import { downloadData } from "../utils/utils";

const ResizableContainer = styled.div`
  background: ${COLORS.slightlyBrighterBackgroundDarkGray};

  bottom: 0px;

  border-top: 1px solid ${COLORS.brightDarkGray};
  /* position: sticky !important; */
  width: 100%;
  height: 100%;
  display: flex;
  padding: 16px 64px;
  @media (max-width: ${sizes.medium}px) {
    padding: 16px 18px;
  }
`;

const Content = styled.div<{ isLoading: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  opacity: ${(props) => (props.isLoading ? 0.5 : 1)};

  // Remove extra spacing in menu
  .ant-menu-item {
    padding-left: 0px !important;
    ::after {
      left: 0px;
    }
  }

  .ace_gutter {
    z-index: 0;
  }

  .ace_gutter-cell {
    background-color: ${COLORS.slightlyBrighterBackgroundDarkGray} !important;
    color: ${COLORS.darkGray};
  }
  .ace_gutter-layer {
    background-color: ${COLORS.slightlyBrighterBackgroundDarkGray} !important;
  }
`;

const AceContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

const ResultDrawerContainer = styled.div`
  display: flex;
  height: 100%;
  z-index: 10;
  flex-direction: column;
`;

type TabType = "HEADERS" | "BODY";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const CenterSpin = styled(Spin)`
  z-index: 10;
  position: relative;
  top: calc(50% - 14px);
  left: calc(50% - 14px);
`;

const JsonViewer = ({ value }: { value: string }) => {
  const aceEditor = React.useRef(null);

  const [height, setHeight] = useState(300);

  const onResize = (w?: number, h?: number) => {
    setHeight(h || 0);
  };

  return (
    <AceContainer className="resizable">
      <ReactResizeDetector handleWidth handleHeight onResize={onResize} />

      <AceEditor
        ref={aceEditor}
        mode="json"
        theme="twilight"
        fontSize={12}
        showPrintMargin={true}
        showGutter={true}
        highlightActiveLine={true}
        value={value}
        wrapEnabled
        readOnly
        width="100%"
        style={{
          width: "100%",
          background: COLORS.slightlyBrighterBackgroundDarkGray,
          position: "relative !important" as any,
        }}
        height={height}
        setOptions={{
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
    </AceContainer>
  );
};

const MenuItem = styled.div`
  color: ${COLORS.nearWhite};

  &:hover {
    color: black;
  }
`;

const responseToDisplay = (
  response: ResponseType
): { string: string; isJson: boolean } => {
  if (response.body) {
    return { string: JSON.stringify(response.body, null, 2), isJson: true };
  }
  if (typeof response.text === "object") {
    return { string: JSON.stringify(response.text, null, 2), isJson: true };
  }

  return { string: response.text, isJson: false };
};

const ResultsDrawer = ({
  response,
  isLoading,
  downloadFileName,
}: {
  response: ResponseType;
  isLoading: boolean;
  // When downloading response - use this file name
  downloadFileName: string;
}) => {
  const [tab, setTab] = React.useState<TabType>("BODY");

  const { string: responseBodyString, isJson } = React.useMemo(
    () => responseToDisplay(response),
    [response]
  );

  const responseHeadersString = React.useMemo(
    () => JSON.stringify(response.headers, null, 2),
    [response.headers]
  );

  const handleMenuClick = (e: { key: string }) => {
    switch (e.key) {
      case "COPY": {
        copy(responseBodyString);

        toast.success("Copied response to clipboard", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        return;
      }
      case "SAVE": {
        downloadData(responseBodyString, isJson, downloadFileName);
        return;
      }
    }
  };

  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ background: COLORS.brightDarkGray, color: COLORS.nearWhite }}
    >
      <Menu.Item key="COPY">
        <MenuItem> Copy to clipboard</MenuItem>
      </Menu.Item>
      <Menu.Item key="SAVE">
        <MenuItem>Save to a file</MenuItem>
      </Menu.Item>
    </Menu>
  );

  return (
    <ResultDrawerContainer>
      <motion.div
        initial="closed"
        exit="closed"
        animate="open"
        style={{ height: "100%" }}
        transition={{ duration: 0.3 }}
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
      >
        <ResizableContainer>
          <>
            {isLoading && <CenterSpin indicator={antIcon} />}
            <Content isLoading={isLoading}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                }}
              >
                <TabMenu
                  onClick={(e) => setTab(e.key as TabType)}
                  selectedKeys={[tab]}
                  mode="horizontal"
                  inlineIndent={0}
                  style={{
                    marginLeft: 20,
                    marginBottom: 24,
                    background: COLORS.slightlyBrighterBackgroundDarkGray,
                  }}
                >
                  <Menu.Item key="BODY">Body</Menu.Item>
                  <Menu.Item key="HEADERS">Headers</Menu.Item>
                </TabMenu>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <div
                    style={{
                      marginRight: 24,
                      fontSize: 11,
                      color: COLORS.lightGray,
                      display: "flex",
                    }}
                  >
                    Status:
                    <div
                      style={{
                        marginLeft: 4,
                        color:
                          response.status < 300
                            ? COLORS.green
                            : response.status < 400
                            ? COLORS.gold
                            : COLORS.red,
                      }}
                    >
                      {response.status} {getReasonPhrase(response.status)}
                    </div>
                  </div>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <a
                      className="ant-dropdown-link"
                      onClick={(e) => e.preventDefault()}
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          marginRight: 2,
                          color: COLORS.darkGray,
                          fontSize: 12,
                          marginBottom: 3,
                        }}
                      >
                        Save response
                      </div>
                      <DownArrowSmall />
                    </a>
                  </Dropdown>
                </div>
              </div>

              <JsonViewer
                value={
                  tab === "BODY" ? responseBodyString : responseHeadersString
                }
              />
            </Content>
          </>
        </ResizableContainer>
      </motion.div>
    </ResultDrawerContainer>
  );
};

export default ResultsDrawer;

/**
 *
 * InputComponents
 *
 */
import {
  Input as _Input,
  Checkbox,
  Radio,
  Space,
  Slider,
  DatePicker as _DatePicker,
  TimePicker as _TimePicker,
  Spin,
  Switch as _Switch,
} from "antd";
import * as React from "react";
import styled from "styled-components/macro";
import { COLORS } from "../utils/colors";

const { TextArea: _TextArea } = _Input;

export const DatePicker = styled(_DatePicker)`
  input {
    color: ${COLORS.nearWhite};
  }
`;

export const TimePicker = styled(_TimePicker)`
  input {
    color: ${COLORS.nearWhite};
  }
`;

const StyledInput = styled(_Input)`
  background-color: ${COLORS.backgroundDarkGray};
  color: ${COLORS.nearWhite};
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLORS.textPlaceholderGray};
  }
  border-color: ${COLORS.textPlaceholderGray};
`;

export const Input = (
  props: { label?: string } & React.ComponentProps<typeof StyledInput>
) => {
  const { label } = props;
  return (
    <WithLabel label={label}>
      <StyledInput {...props}></StyledInput>
    </WithLabel>
  );
};

export const WithLabel = (props: {
  label?: string;
  children: React.ReactChild;
  outerstyle?: {};
}) => {
  const { children, label, outerstyle = {} } = props;

  return (
    <div style={{ display: "flex", flexDirection: "column", ...outerstyle }}>
      {label && (
        <div
          style={{
            marginBottom: 6,
            color: COLORS.darkGray,
            fontWeight: 400,
            marginLeft: 10,
            fontSize: 13,
          }}
        >
          {label}
        </div>
      )}
      {children}
    </div>
  );
};

const TextAreaStyled = styled(_TextArea)`
  background-color: ${COLORS.backgroundDarkGray};
  color: ${COLORS.nearWhite};
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLORS.textPlaceholderGray};
    font-weight: 400;
  }
  border-color: ${COLORS.textPlaceholderGray};
`;

export const TextArea = (
  props: { label?: string; outerstyle?: {} } & React.ComponentProps<
    typeof TextAreaStyled
  >
) => {
  const { label, outerstyle } = props;
  return (
    <WithLabel outerstyle={outerstyle} label={label}>
      <TextAreaStyled {...props}></TextAreaStyled>
    </WithLabel>
  );
};

import * as React from "react";
import { ParameterData } from "../components/ParameterEditor";
import { navigate } from "gatsby";
import copy from "copy-to-clipboard";

import { toast } from "react-toastify";
import { SendActionType } from "../components/SendBar";
import { ApiNameType, API_ADDITIONAL_METADATA } from "./apis";
import { keys, pickBy } from "lodash";
import { PROXY_URL, SITE_URL } from "./constants";
import SwaggerClient from "swagger-client";
import HTTPSnippet from "httpsnippet";
import { getLinkToOperation } from "./utils";

export type ResponseType = {
  body: unknown;
  headers: Record<string, unknown>;
  status: number;
  ok: boolean;
  statusText: string;
  url: string;
};

export const authorize = async (
  apiName: ApiNameType,
  scopes: string[],
  operationId?: string
) => {
  const finalCallbackUrl = `${SITE_URL}/${apiName}${
    operationId ? getLinkToOperation(operationId) : ""
  }`;

  const oauthService =
    API_ADDITIONAL_METADATA[apiName].oauthNameOverride || apiName;

  const scopeDelimiter =
    API_ADDITIONAL_METADATA[apiName].customScopeDelimiter || ",";

  window.location.href = `${PROXY_URL}/connect/${oauthService}?callback=${encodeURIComponent(
    finalCallbackUrl
  )}&scope=${encodeURIComponent(scopes.join(scopeDelimiter))}`;
};

const removeEmptyStringFields = (data: { [key: string]: any }) => {
  // Since if someones adds something and then removes it, we shouldn't include anything
  return pickBy(data, (val) => val !== "");
};

const toHarArray = (obj?: { [key: string]: string }) => {
  if (!obj) {
    return [];
  }

  return keys(obj).map((k) => ({
    name: k,
    value: obj[k],
  }));
};

// example HAR request: https://apiembed.com/sample.json
export const getRequestInHARFormat = async (
  apiName: ApiNameType,
  operationId: string,
  data: ParameterData,
  // Overrides for undefined values
  sharedData: ParameterData
) => {
  const specUrl = `${PROXY_URL}/spec/${apiName}`;

  const spec = await fetch(specUrl).then((res) => res.json());

  const body = generateRequestBody(apiName, operationId, data, sharedData);

  const openApiRequest = await SwaggerClient.buildRequest({
    spec,
    ...body,
  });

  const harRequest = {
    method: openApiRequest.method,
    url: openApiRequest.url,
    headers: [
      ...toHarArray(openApiRequest.headers),
      ...toHarArray(body.authorization),
    ],
    postData: openApiRequest.body
      ? {
          mimeType: "application/json",
          text: JSON.stringify(openApiRequest.body),
        }
      : undefined,
  };

  return harRequest as HTTPSnippet.Data;
};

export const copyCurl = async (
  apiName: ApiNameType,
  operationId: string,
  data: ParameterData,
  // Overrides for undefined values
  sharedData: ParameterData
) => {
  const requestInHARFormat = await getRequestInHARFormat(
    apiName,
    operationId,
    data,
    sharedData
  );

  const snippet = new HTTPSnippet(requestInHARFormat);

  const curlScript = snippet.convert("shell", "curl", {
    indent: "\t",
  });

  if (curlScript) {
    copy(curlScript);

    toast.success("Copied cURL request to clipboard", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  } else {
    console.error(`Error generating cURL script ${curlScript}`);
  }
};

const generateRequestBody = (
  apiName: ApiNameType,
  operationId: string,
  data: ParameterData,
  // Overrides for undefined values
  sharedData: ParameterData
) => {
  const dataBody = removeEmptyStringFields({
    ...sharedData.BODY,
    ...data.BODY,
  });

  const parameters = removeEmptyStringFields({
    // sharedData only takes effect if data doesnt define a value for that entry
    ...sharedData.PATH_PARAMS,
    ...sharedData.QUERY_PARAMS,
    ...sharedData.HEADERS,
    ...data.PATH_PARAMS,
    ...data.QUERY_PARAMS,
    ...data.HEADERS,
  });

  const body = {
    apiName,
    operationId,
    // todo - should this just be put into "headers" below? then remove logic that adds
    // it on backend and in getRequestInHARFormat. Though maybe not since we might have non header
    // based auth in future
    authorization: {
      ...sharedData.AUTHORIZATION,
      ...data.AUTHORIZATION,
    },
    serverVariables: sharedData.SERVER_VARIABLES,
    parameters,
    ...(Object.keys(dataBody).length > 0 && { requestBody: dataBody }),
    headers: { ...sharedData.HEADERS, ...data.HEADERS },
  } as any;

  return body;
};

export const executeRequest = async (
  apiName: ApiNameType,
  operationId: string,
  data: ParameterData,
  // Overrides for undefined values
  sharedData: ParameterData
) => {
  const body = generateRequestBody(apiName, operationId, data, sharedData);

  let response;
  let error;
  try {
    response = await fetch(`${PROXY_URL}/execute/${apiName}`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }).then((res) => res.json());
  } catch (err) {
    // Only show error if we're not displaying the error in the UI (e.g.
    // if it's an error before the request is made, like with missing args errors)
    error = (err as { message: string }).message;
  }

  if (!response?.result) {
    toast.error(error || response?.description, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return;
  }
  return response.result;
};

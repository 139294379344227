import * as React from "react";
import { toast } from "react-toastify";
import { Button } from "./Button";
import Modal from "react-modal";
import styled from "styled-components";
import { COLORS } from "../utils/colors";
import { SpeakerIcon, XIcon } from "./icons";
import { MediumTitleDarkGray } from "./common";
import { Input, TextArea } from "./InputComponents";
import { notifySlack } from "../utils/utils";
import { customModalStyles } from "../utils/constants";

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${COLORS.nearWhite};
`;

const ShareFeedback = () => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const [feedback, setFeedback] = React.useState("");
  const [email, setEmail] = React.useState("");

  const onSend = () => {
    notifySlack(
      [
        "*New feedback:* ",
        feedback,
        "\n *Location:* ",
        window.location.href,
        "\n *email:* ",
        email,
      ].join("")
    );
    setModalOpen(false);

    toast.success("Thanks for helping to improve tryapis.com", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <Button
        type="link"
        hideshadow
        onClick={async () => {
          setModalOpen(true);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: 4,
        }}
      >
        <MediumTitleDarkGray style={{ marginRight: 3 }}>
          Feedback on this page?
        </MediumTitleDarkGray>
        <SpeakerIcon />
      </Button>

      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 24,
          }}
        >
          <Title>Share feedback</Title>
          <div
            style={{
              height: 30,
              width: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(false)}
          >
            <XIcon />
          </div>
        </div>

        <TextArea
          autoFocus
          outerstyle={{ autoFocus: true }}
          style={{ width: "100%" }}
          placeholder="How can we improve this page?"
          onChange={(e) => setFeedback(e.target.value)}
          value={feedback}
        ></TextArea>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ marginTop: 16 }}
          placeholder="Your email"
        ></Input>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <Button
            hideshadow
            type="primary"
            disabled={!feedback || !email}
            onClick={() => {
              onSend();
            }}
          >
            Send feedback
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ShareFeedback;

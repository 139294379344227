import * as React from "react";
import styled from "styled-components/macro";

import { Select as AntSelect } from "antd";
import { COLORS } from "../utils/colors";
const { Option, OptGroup } = AntSelect;

const StyledAntSelect = styled(AntSelect)`
  width: 100%;
  .ant-select-selector {
    background-color: ${COLORS.backgroundDarkGray} !important;
    color: ${COLORS.nearWhite} !important;
    border: 1px solid ${COLORS.brightDarkGrayBorder} !important;
  }
  .ant-select-selection-placeholder {
    color: ${COLORS.textPlaceholderGray};
    font-weight: 400;
    font-size: 13px;
  }
`;

type OptionType = { value: string | number; label: string }[];

interface SelectProps {
  options?: OptionType;
  groupedoptions?: {
    category: string;
    categoryValue: string;
    options: OptionType;
  }[];
  onChange: (newValue: string) => void;
}

export const Select = (
  props: SelectProps & React.ComponentProps<typeof AntSelect>
) => {
  const { options, onChange, groupedoptions } = props;

  return (
    <StyledAntSelect
      {...props}
      onChange={(v) => onChange(v as string)}
      suffixIcon={
        <svg
          width="16"
          height="15"
          viewBox="0 0 16 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.25 5.625L8 9.375L11.75 5.625"
            stroke={COLORS.lightGray}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      }
    >
      {options?.map((o) => (
        <Option key={o.value} value={o.value}>
          {o.label}
        </Option>
      ))}
      {groupedoptions?.map((group) => (
        <OptGroup label={group.category} key={group.category}>
          {group.options?.map((o) => (
            <Option
              value={JSON.stringify({
                categoryValue: group.categoryValue,
                value: o.value,
              })}
              key={`${group.category}-${o.value}`}
            >
              {o.label}
            </Option>
          ))}
        </OptGroup>
      ))}
    </StyledAntSelect>
  );
};

import * as React from "react";
import styled from "styled-components";
import { customModalStyles } from "../utils/constants";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import { Button } from "antd";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { OpenAPIV3 } from "openapi-types";
import { useState } from "react";
import { Menu, Tooltip } from "antd";
import { COLORS } from "../utils/colors";
import { Input, InputNumber } from "antd";
import { Select } from "./Select";
import { SecondaryButton, TabMenu } from "./common";
import { keys } from "lodash";
import { getBodyParams, getLinkToOperation, truncate } from "../utils/utils";
import { sizes } from "../utils/sizes";
import { authorize } from "../utils/executor";
import { ApiNameType, API_ADDITIONAL_METADATA } from "../utils/apis";
import { WhiteGoArrow, SmallDarkGrayLock } from "./icons";
import { navigate } from "gatsby-link";
import { SecurityModal } from "./SecurityModal";
import { Button as CustomButton } from "./Button";

export type TabType =
  | "SERVER_VARIABLES"
  | "AUTHORIZATION"
  | "QUERY_PARAMS"
  | "HEADERS"
  | "BODY"
  | "PATH_PARAMS";

export type ValidationErrorType = { parameterType: TabType; field: string };

const JsonEditorContainer = styled.div`
  .jsoneditor {
    border-radius: 4px;
  }

  .jsoneditor-menu {
    background-color: ${COLORS.primary};
  }
`;
const KeyValueContainer = styled.div`
  margin-top: 24px;

  border-radius: 8px;
  background: ${COLORS.brightDarkGray};
  max-width: 800px;
  border: 1px solid ${COLORS.brightDarkGrayBorder};
`;

const KeyValueItem = styled.div<{ hideBottomBorder?: boolean }>`
  padding: 10px 20px;
  display: flex;
  flex-direction: row;

  @media (max-width: ${sizes.medium}px) {
    flex-direction: column;
    align-items: flex-start;
  }

  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  border-bottom: ${(props) =>
    props.hideBottomBorder ? "" : `1px solid ${COLORS.brightDarkGrayBorder}`};
`;

const ValueInputContainer = styled.div<{ hasError: boolean | undefined }>`
  @media (max-width: ${sizes.medium}px) {
    width: 100%;
    margin-top: 8px;
  }

  input {
    background: ${COLORS.backgroundDarkGray};
    border: ${(props) =>
      props.hasError
        ? `1px solid ${COLORS.red}`
        : `1px solid ${COLORS.brightDarkGrayBorder}`};
    color: ${COLORS.nearWhite};
    width: 400px;

    @media (max-width: ${sizes.medium}px) {
      width: 100%;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${COLORS.textPlaceholderGray};
      font-weight: 400;
      font-size: 13px;
    }
  }
`;

const NumberInputContainer = styled.div`
  .ant-input-number-handler {
    background: ${COLORS.backgroundDarkGray};
  }
  svg {
    color: ${COLORS.lightGray};
  }
`;

const getParameterType = (parameter: OpenAPIV3.ParameterObject) => {
  const parameterType = parameter.schema?.type || parameter.type;
  const parameterDefault = parameter.schema?.default || parameter.default;
  const parameterEnum = parameter.schema?.enum || parameter.enum;

  return {
    parameterType,
    parameterDefault,
    parameterEnum,
  };
};

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${COLORS.nearWhite};
`;

const ObjectEditor = ({
  value,
  parameter,
  onChange,
  parameterType,
}: {
  value: any;
  parameter: OpenAPIV3.ParameterObject;
  onChange: (v: any) => void;
  parameterType: "object" | "array";
}) => {
  /**
todo - we should use parameter.items to guide people on the entire structure of the required parameter


   * 
   * 
   * in query:
   {
     
schema: {
  items: {type: 'string'}
  type: "array"
}
style: "form"



/googlesheets/api/sheets_spreadsheets_values_append
(works now!)

items: {
  items: {}
  type: "array"
}
name: "values"
type: "array"



3. gmail_users_messages_batchDelete
provide array of Ids of emails to delete


4. Crucial: gmail_users_drafts_create
Need to be able to craft a "message" object!!



5. see stripe /v1/sources/source


6. see zoom meetingCreate

recurrence object


7, Okta /createUser
has objects and arrays!

8. Google maps
elevation


   */

  const [modalOpen, setModalOpen] = useState(false);
  console.log("test: ", JSON.stringify(value));
  const stringVal =
    value == null || value === "" ? undefined : JSON.stringify(value);

  const defaultValue = parameterType === "object" ? {} : [];

  return (
    <div>
      <Input
        type=""
        onClick={() => {
          setModalOpen(true);
        }}
        placeholder="Click to edit"
        style={{ cursor: "pointer" }}
        value={stringVal}
      />
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customModalStyles}
        contentLabel="Example Modal"
        onAfterOpen={() => (document.body.style.overflow = "hidden")}
        onAfterClose={() => (document.body.style.overflow = "unset")}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 6,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginRight: 8,
              color: COLORS.darkGray,
              textDecoration: "underline",
              cursor: "help",
            }}
          >
            {parameter.description ? (
              <Tooltip
                title={
                  <ReactMarkdown linkTarget="_blank">
                    {parameter.description}
                  </ReactMarkdown>
                }
              >
                <Title>{parameter.name}</Title>
              </Tooltip>
            ) : (
              <Title>{parameter.name}</Title>
            )}
          </div>

          <div style={{ marginLeft: 8, color: COLORS.nearWhite }}>
            {parameterType}
          </div>
        </div>
        <div style={{ color: COLORS.darkGray, marginBottom: 18 }}>
          This parameter requires an {parameterType} as input
        </div>
        <JsonEditorContainer>
          <Editor
            value={value === undefined ? defaultValue : value}
            onChange={(v: any) => onChange(v)}
            mode="code"
            enableTransform={false}
            enableSort={false}
          />
        </JsonEditorContainer>
        <div style={{ color: COLORS.darkGray, marginTop: 4 }}>
          Hint: click the repair icon to fix invalid JSON
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <CustomButton
            hideshadow
            type="primary"
            onClick={() => {
              setModalOpen(false);
            }}
          >
            Done
          </CustomButton>
        </div>
      </Modal>
    </div>
  );
};

const ValueInput = ({
  parameter,
  onUpdate,
  value,
}: {
  value: any;
  parameter: OpenAPIV3.ParameterObject;
  onUpdate: (key: string, value: ValueType) => void;
}) => {
  const { parameterType, parameterDefault, parameterEnum } =
    getParameterType(parameter);

  const chooseValuePrompt = parameterEnum ? "Select value" : "Enter value";

  const placeholder = parameter.example
    ? parameter.example
    : parameterDefault
    ? `Defaults to ${parameterDefault}`
    : chooseValuePrompt;

  const props = {
    value,
    placeholder,
    defaultValue: parameterDefault,
  };

  if (parameterEnum) {
    return (
      <Select
        {...props}
        style={{ width: 400, maxWidth: "100%" }}
        options={parameterEnum.map((e: string) => ({
          value: e,
          label: e,
        }))}
        onChange={(v) => {
          onUpdate(parameter.name, v);
        }}
      />
    );
  }

  switch (parameterType) {
    case "integer":
      return (
        <NumberInputContainer>
          <InputNumber
            bordered={false}
            {...props}
            onChange={(e) => onUpdate(parameter.name, e)}
            type="number"
            style={{ width: "100%" }}
          />
        </NumberInputContainer>
      );
    case "boolean":
      return (
        <Select
          {...props}
          value={value === true ? "true" : value === false ? "false" : value}
          style={{ width: 400, maxWidth: "100%" }}
          options={[
            { value: "true", label: "true" },
            { value: "false", label: "false" },
          ]}
          onChange={(v) => {
            onUpdate(parameter.name, v === "true" ? true : false);
          }}
        />
      );
    case "string":
      return (
        <Input
          {...props}
          type=""
          onChange={(e) => onUpdate(parameter.name, e.target.value)}
        />
      );
    case "object":
    case "array":
      return (
        <ObjectEditor
          {...props}
          onChange={(value) => onUpdate(parameter.name, value)}
          parameter={parameter}
          parameterType={parameterType}
        />
      );
    default: {
      console.log(
        `${parameterType} type parameter not supported - defaulting to string`
      );
      return (
        <Input
          {...props}
          type=""
          onChange={(e) => onUpdate(parameter.name, e.target.value)}
        />
      );
    }
  }
};

const KeyValueEditor = ({
  parameters,
  onUpdate,
  keyWithValidationError,
  dataForTab,
  defaultDataForTab,
}: {
  dataForTab: Record<string, unknown>;
  parameters: OpenAPIV3.ParameterObject[];
  onUpdate: (key: string, value: ValueType) => void;
  keyWithValidationError?: string;
  defaultDataForTab: Record<string, unknown>;
}) => {
  if (!parameters.length) {
    return <div />;
  }

  const sortedParameters = parameters.sort((a, b) => (a.required ? -1 : 1));

  const dataWithDefaults = {
    ...defaultDataForTab,
    ...dataForTab,
  };

  //  todo - add ability to "x" out query params (remove them) and add new ones
  return (
    <KeyValueContainer>
      {sortedParameters.map((parameter, i) => (
        <KeyValueItem
          key={parameter.name}
          hideBottomBorder={i === sortedParameters.length - 1}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <>
              {parameter.description ? (
                <div
                  style={{
                    marginRight: 8,
                    color: COLORS.lightGray,
                    textDecoration: "underline",
                    cursor: "help",
                  }}
                >
                  <Tooltip
                    title={
                      <ReactMarkdown linkTarget="_blank">
                        {parameter.description}
                      </ReactMarkdown>
                    }
                  >
                    {parameter.name}
                  </Tooltip>
                </div>
              ) : (
                <div
                  style={{
                    marginRight: 8,
                    color: COLORS.lightGray,
                  }}
                >
                  {parameter.name}
                </div>
              )}

              {getParameterType(parameter).parameterType && (
                <div style={{ marginRight: 8, color: COLORS.darkGray }}>
                  {getParameterType(parameter).parameterType}
                </div>
              )}
              {parameter.required === true && (
                <div style={{ color: COLORS.red, marginRight: 8 }}>
                  {" "}
                  required
                </div>
              )}
            </>
          </div>

          <ValueInputContainer
            hasError={keyWithValidationError === parameter.name}
          >
            <ValueInput
              parameter={parameter}
              onUpdate={onUpdate}
              value={dataWithDefaults[parameter.name]}
            />
          </ValueInputContainer>
        </KeyValueItem>
      ))}
    </KeyValueContainer>
  );
};

export type ValueType = any;
export type ParameterData = {
  [key in TabType]: {
    [key: string]: ValueType;
  };
};

export const ParameterTypeToOpenAPIKey: { [tab in TabType]: string } = {
  AUTHORIZATION: "",
  SERVER_VARIABLES: "serverVariables",
  HEADERS: "header",
  PATH_PARAMS: "path",
  QUERY_PARAMS: "query",
  BODY: "body",
};

export const tabTypeForOpenApiParameterType = (parameterType: string) => {
  return Object.keys(ParameterTypeToOpenAPIKey).find(
    (key) => ParameterTypeToOpenAPIKey[key as TabType] === parameterType
  ) as TabType;
};

/**
 *
 * When you authorize, you definitely want that to be the token for this endpoint (i.e. set any custom inputs to undefined)
 * The default when authorization is undefined (but not empty string!) is always the global authorization
 * You can edit the authorization
 * If you change it to empty string, it should stay empty string (unless you re-authorize)
 *
 */

/**
 *
 * when you auth, bearer gets filled in and is sent as part of data
 * we remove the query param once we update the data
 * when you load page, persisted data loads
 */
const Authorization = ({
  apiName,
  show,
  operationId,
  scopes,
  onUpdate,
  data,
  persistedData,
  setPersistedData,
  locationQueryParams,
  switchToAuthTab,
}: {
  show: boolean;
  scopes: string[];
  apiName: ApiNameType;
  operationId: string;
  locationQueryParams: Record<string, string>;
  onUpdate: (key: string, value: ValueType) => void;
  data: {
    [key: string]: any;
  };
  setPersistedData: (d: Record<string, unknown>) => void;
  persistedData: Record<string, unknown>;
  switchToAuthTab: () => void;
}) => {
  const defaultAccessToken = persistedData.Authorization;
  const [modalOpen, setModalOpen] = React.useState(false);

  useEffect(() => {
    if (locationQueryParams.access_token) {
      const authorizationHeaderFromQueryParams = `Bearer ${locationQueryParams.access_token}`;

      // remove query param (hacky)
      const accessTokenAlreadySaved =
        authorizationHeaderFromQueryParams === persistedData.Authorization;
      navigate(`/${apiName}${getLinkToOperation(operationId)}`);

      if (!accessTokenAlreadySaved) {
        setPersistedData({
          ...persistedData,
          Authorization: authorizationHeaderFromQueryParams,
        });

        // When you authorize on an endpoint, we overwrite the Authorization header even if
        // you manually entered it. However, this won't override other endpoints that have
        // manually entered Authorization headers (for better or worse). But if they are
        // just using the default authorization then the other endpoints *should* be updated
        onUpdate("Authorization", undefined);

        switchToAuthTab();

        toast.success("Authorization token obtained", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
  }, [
    setPersistedData,
    locationQueryParams.access_token,
    apiName,
    operationId,
    persistedData.accessToken,
    switchToAuthTab,
    onUpdate,
    persistedData,
  ]);

  if (!show) {
    return <div />;
  }

  const parametersToEdit = [
    {
      name: "Authorization",
      description:
        "The Authorization header can be used to pass credentials to protected endpoints.",
      example: "Bearer <your_token>",
      in: "header",
    },
  ];
  const hasCustomButtonText =
    !!API_ADDITIONAL_METADATA[apiName].customSignInText;

  const buttonContents = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {API_ADDITIONAL_METADATA[apiName].signInIcon ||
        API_ADDITIONAL_METADATA[apiName].icon}
      <div
        style={{
          marginLeft: 8,
          marginRight: 8,
          color: hasCustomButtonText && COLORS.darkDarkGray,
        }}
      >
        {API_ADDITIONAL_METADATA[apiName].customSignInText ||
          (defaultAccessToken ? "Re-authorize" : "Authorize")}
      </div>
      {!defaultAccessToken && !hasCustomButtonText && <WhiteGoArrow />}
    </div>
  );

  return (
    <>
      <div
        style={{
          marginTop: 24,
          marginLeft: 8,
          display: "flex",
          alignItems: "center",
        }}
      >
        {defaultAccessToken ? (
          <SecondaryButton
            style={{
              height: 30,
              paddingRight: 8,
              paddingLeft: 12,
              background: hasCustomButtonText && COLORS.nearWhite,
            }}
            onClick={() => {
              authorize(apiName, scopes, operationId);
            }}
          >
            {buttonContents}
          </SecondaryButton>
        ) : (
          <Button
            style={{
              borderRadius: 6,
              background: hasCustomButtonText && COLORS.nearWhite,
            }}
            type={defaultAccessToken ? "default" : "primary"}
            onClick={() => {
              authorize(apiName, scopes, operationId);
            }}
          >
            {buttonContents}
          </Button>
        )}

        {scopes.length > 0 && (
          <div
            style={{
              marginLeft: 20,
              fontSize: 12,
              color: COLORS.darkGray,
            }}
          >
            Scopes requested: {truncate(scopes.join(", "), 50)}
          </div>
        )}
      </div>

      <KeyValueEditor
        parameters={parametersToEdit}
        onUpdate={onUpdate}
        dataForTab={data}
        defaultDataForTab={persistedData}
      />
      <a
        style={{
          fontSize: 12,
          color: COLORS.darkGray,
          textDecoration: "underline",
          display: "flex",
          alignItems: "center",
          marginTop: 12,
          marginLeft: 8,
        }}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        <SmallDarkGrayLock />
        tryapis.com never stores your requests or responses
      </a>
      <SecurityModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
};
const ServerVariables = ({
  show,
  persistedData,
  setPersistedData,
  serverVariables,
}: {
  show: boolean;
  setPersistedData: (d: Record<string, unknown>) => void;
  persistedData: Record<string, unknown>;

  serverVariables: {
    [variable: string]: OpenAPIV3.ServerVariableObject;
  };
}) => {
  if (!show) {
    return <div />;
  }

  const parametersToEdit = Object.keys(serverVariables).map(
    (serverVariable) => ({
      description: serverVariables[serverVariable].description,
      in: "serverVariables",
      example: serverVariables[serverVariable].default,
      name: serverVariable,
      required: serverVariables[serverVariable].required,
    })
  );

  return (
    <>
      <KeyValueEditor
        parameters={parametersToEdit}
        onUpdate={(key: string, value: ValueType) => {
          setPersistedData({
            ...persistedData,
            [key]: value,
          });
        }}
        dataForTab={persistedData}
        defaultDataForTab={persistedData}
      />
    </>
  );
};

const ParameterEditor = ({
  data,
  setData,
  sharedData,
  setSharedData,
  validationError,
  operation,
  clearValidationError,
  apiName,
  locationQueryParams,
  serverVariables,
}: {
  operation: OpenAPIV3.OperationObject;
  validationError?: ValidationErrorType;
  data: ParameterData;
  setData: (data: ParameterData) => void;
  sharedData: ParameterData;
  setSharedData: (data: ParameterData) => void;
  clearValidationError: () => void;
  apiName: ApiNameType;
  locationQueryParams: Record<string, string>;
  serverVariables:
    | {
        [variable: string]: OpenAPIV3.ServerVariableObject;
      }
    | undefined;
}) => {
  const parameters = (operation.parameters ||
    []) as OpenAPIV3.ParameterObject[];

  const pathParams = parameters.filter(
    (p) => p.in === ParameterTypeToOpenAPIKey.PATH_PARAMS
  );
  const queryParams = parameters.filter(
    (p) => p.in === ParameterTypeToOpenAPIKey.QUERY_PARAMS
  );
  const headerParams = parameters.filter(
    (p) => p.in === ParameterTypeToOpenAPIKey.HEADERS
  );

  const { bodyParams, contentType } = getBodyParams(operation);

  const scopes =
    API_ADDITIONAL_METADATA[apiName].scopesForOperation?.(operation) || [];

  const firstTabToShow = () => {
    if (serverVariables && keys(sharedData.SERVER_VARIABLES).length === 0) {
      return "SERVER_VARIABLES";
    }

    if (
      scopes === true ||
      (scopes.length > 0 && keys(sharedData.AUTHORIZATION).length === 0)
    ) {
      return "AUTHORIZATION";
    }

    return pathParams.length > 0
      ? "PATH_PARAMS"
      : queryParams.length > 0
      ? "QUERY_PARAMS"
      : bodyParams.length > 0
      ? "BODY"
      : headerParams.length > 0
      ? "HEADERS"
      : scopes.length > 0
      ? "AUTHORIZATION"
      : undefined;
  };

  const [tab, setTab] = useState<TabType | undefined>(firstTabToShow());

  useEffect(() => {
    if (validationError) {
      setTab(validationError.parameterType);
    }
  }, [validationError]);

  let parametersToEdit: OpenAPIV3.ParameterObject[] = [];
  switch (tab) {
    case "PATH_PARAMS": {
      parametersToEdit = pathParams;
      break;
    }
    case "QUERY_PARAMS": {
      parametersToEdit = queryParams;
      break;
    }
    case "HEADERS": {
      parametersToEdit = headerParams;
      break;
    }
    case "BODY": {
      parametersToEdit = bodyParams;
      break;
    }
  }

  if (!tab) {
    return <div />;
  }

  const onUpdate = (key: string, value: ValueType, keyTab: TabType) => {
    if (
      validationError?.parameterType === keyTab &&
      key === validationError?.field &&
      value
    ) {
      clearValidationError();
    }

    setData({
      ...data,
      [keyTab]: {
        ...data[keyTab],
        [key]: value,
      },
    });
  };

  const keyWithValidationError =
    validationError?.parameterType === tab ? validationError.field : undefined;

  return (
    <div>
      <TabMenu
        onClick={(e) => setTab(e.key as TabType)}
        selectedKeys={[tab]}
        mode="horizontal"
      >
        {serverVariables && (
          <Menu.Item key="SERVER_VARIABLES">Server variables</Menu.Item>
        )}
        {(scopes === true || scopes.length > 0) && (
          <Menu.Item key="AUTHORIZATION">Authorization</Menu.Item>
        )}
        {pathParams.length > 0 && (
          <Menu.Item key="PATH_PARAMS">
            Path parameters ({pathParams.length})
          </Menu.Item>
        )}
        {queryParams.length > 0 && (
          <Menu.Item key="QUERY_PARAMS">
            Query parameters ({queryParams.length})
          </Menu.Item>
        )}
        {headerParams.length > 0 && (
          <Menu.Item key="HEADERS">Headers ({headerParams.length})</Menu.Item>
        )}
        {bodyParams.length > 0 && (
          <Menu.Item key="BODY">Body ({bodyParams.length})</Menu.Item>
        )}
      </TabMenu>
      {tab === "BODY" && (
        <div
          style={{
            marginLeft: 20,
            marginTop: 8,
            fontSize: 12,
            color: COLORS.darkGray,
          }}
        >
          Content Type: {contentType}
        </div>
      )}

      <Authorization
        show={tab === "AUTHORIZATION"}
        operationId={operation.operationId as string}
        apiName={apiName}
        onUpdate={(key: string, value: ValueType) =>
          onUpdate(key, value, "AUTHORIZATION")
        }
        data={data.AUTHORIZATION}
        setPersistedData={(d: Record<string, unknown>) => {
          setSharedData({ ...sharedData, AUTHORIZATION: d });
        }}
        persistedData={sharedData.AUTHORIZATION}
        scopes={scopes === true ? [] : scopes}
        locationQueryParams={locationQueryParams}
        switchToAuthTab={() => setTab("AUTHORIZATION")}
      />

      {serverVariables && (
        <ServerVariables
          show={tab === "SERVER_VARIABLES"}
          serverVariables={serverVariables}
          setPersistedData={(d: Record<string, unknown>) => {
            setSharedData({ ...sharedData, SERVER_VARIABLES: d });
          }}
          persistedData={sharedData.SERVER_VARIABLES}
        />
      )}

      <KeyValueEditor
        parameters={parametersToEdit}
        onUpdate={(key: string, value: ValueType) => onUpdate(key, value, tab)}
        keyWithValidationError={keyWithValidationError}
        dataForTab={data[tab]}
        defaultDataForTab={sharedData[tab]}
      />
    </div>
  );
};

export default ParameterEditor;

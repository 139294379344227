import Modal from "react-modal";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import AceEditor from "react-ace";
import { Select } from "./Select";
import * as React from "react";
import styled from "styled-components/macro";
import { Button as AntdButton } from "antd";
import { COLORS } from "../utils/colors";
import { customModalStyles } from "../utils/constants";
import { Button } from "./Button";
import HTTPSnippet from "httpsnippet";
import { ApiNameType } from "../utils/apiNames";
import { ParameterData } from "./ParameterEditor";
import { getRequestInHARFormat } from "../utils/executor";
import "ace-builds/src-noconflict/mode-javascript";
import { CopyToClipboardIcon, XIcon } from "./icons";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { MediumTitleDarkGray } from "./common";

const AceContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
`;

interface Props {
  show: boolean;
  hide: () => void;
  apiName: ApiNameType;
  operationId: string;
  data: ParameterData;
  // Overrides for undefined values
  sharedData: ParameterData;
}

const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  color: ${COLORS.nearWhite};
`;

function ShowCodeModal({
  show,
  hide,
  apiName,
  operationId,
  data,
  sharedData,
}: Props) {
  const [requestInHARFormat, setRequestInHARFormat] = useState<
    HTTPSnippet.Data | undefined
  >();

  const [language, setLanguage] = useState<{
    categoryValue: string;
    value: string;
  }>({
    categoryValue: "python",
    value: "requests",
  });

  useEffect(() => {
    (async () => {
      const r = await getRequestInHARFormat(
        apiName,
        operationId,
        data,
        sharedData
      );
      setRequestInHARFormat(r);
    })();
  }, [apiName, operationId, data, sharedData]);

  const languages = (HTTPSnippet as any).availableTargets().map((l) => ({
    category: l.title,
    categoryValue: l.key,
    options: l.clients.map((c) => ({
      value: c.key,
      label: c.title,
    })),
  }));

  let curlScript: string | undefined | boolean;

  if (requestInHARFormat) {
    const snippet = new HTTPSnippet(requestInHARFormat);

    curlScript = snippet.convert(language.categoryValue, language.value);
  }

  const modalStyle = customModalStyles;
  modalStyle.content.maxWidth = 600;
  modalStyle.content.top = "50%";

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Modal
      isOpen={show}
      onRequestClose={hide}
      style={modalStyle}
      contentLabel=""
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 24,
        }}
      >
        <Title>Generate code</Title>
        <div
          style={{
            height: 30,
            width: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
          onClick={() => hide()}
        >
          <XIcon />
        </div>
      </div>
      <MediumTitleDarkGray style={{ marginBottom: 8 }}>
        Library to use
      </MediumTitleDarkGray>
      <Select
        showSearch
        value={JSON.stringify(language)}
        style={{ width: "100%" }}
        groupedoptions={languages}
        onChange={(v) => {
          setLanguage(JSON.parse(v));
        }}
      />

      {curlScript && typeof curlScript === "string" ? (
        <>
          <MediumTitleDarkGray style={{ marginBottom: 4, marginTop: 24 }}>
            {
              languages.find((l) => l.categoryValue === language.categoryValue)
                .category
            }{" "}
            code
          </MediumTitleDarkGray>
          <AceContainer style={{ marginTop: 12 }}>
            <AceEditor
              mode="javascript"
              theme="twilight"
              fontSize={12}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              value={curlScript as string}
              wrapEnabled
              readOnly
              maxLines={25}
              width="100%"
              style={{
                width: "100%",
                background: COLORS.slightlyBrighterBackgroundDarkGray,
                position: "relative !important" as any,
              }}
              setOptions={{
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </AceContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 24,
            }}
          >
            <Button
              hideshadow
              style={{ display: "flex", alignItems: "center" }}
              type="primary"
              onClick={() => {
                copy(curlScript as string);

                toast.success("Copied snippet to clipboard", {
                  position: "top-center",
                  autoClose: 2000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                });
              }}
            >
              <CopyToClipboardIcon />
              Copy
            </Button>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: 64,
          }}
        >
          <Spin indicator={antIcon} />
        </div>
      )}
    </Modal>
  );
}

export default ShowCodeModal;

import * as React from "react";

import { useRef } from "react";

import { toast } from "react-toastify";

import useLocalStorage from "../utils/useLocalStorage";

import Layout from "../components/Layout";

import { useState } from "react";

import SplitPane from "react-split-pane";
import * as queryString from "query-string";

import Pane from "react-split-pane/lib/Pane";
import rehypeRaw from "rehype-raw";

import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import EndpointsNavigation from "./EndpointsNavigation";
import {
  EndpointSummaryType,
  EndpointType,
  getBodyParams,
  replaceAll,
  throwError,
} from "../utils/utils";

import {
  ApiMetadataType,
  ApiNameType,
  API_ADDITIONAL_METADATA,
} from "../utils/apis";
import { OpenInNewTab } from "../components/icons";

import {
  generateUrl,
  LargeTitleLightGray,
  MainContent,
  MediumTitleDarkGray,
  MediumTitleDarkGrayH1,
  MediumTitleNearWhite,
} from "../components/common";
import SendBar, { SendActionType } from "../components/SendBar";
import ParameterEditor, {
  ParameterData,
  tabTypeForOpenApiParameterType,
  ValidationErrorType,
} from "../components/ParameterEditor";
import { OpenAPIV3 } from "openapi-types";

import { copyCurl, executeRequest, ResponseType } from "../utils/executor";
import ResultsDrawer from "../components/ResultsDrawer";

import { sizes } from "../utils/sizes";
import { Link } from "gatsby";

import ShareFeedback from "../components/ShareFeedback";
import ShowCodeModal from "../components/ShowCodeModal";
import FAQ from "../components/Faq";

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinksContainer = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${sizes.mediumLarge}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${sizes.small}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const ViewDocs = styled.a`
  display: flex;
  align-items: center;
`;

const checkForValidationError = (
  data: ParameterData,
  apiEndpoint: EndpointType
): ValidationErrorType | undefined => {
  if (!apiEndpoint.operation.parameters) {
    return;
  }

  for (const param of apiEndpoint.operation
    .parameters as OpenAPIV3.ParameterObject[]) {
    if (param.required === true) {
      const parameterType = tabTypeForOpenApiParameterType(param.in);
      if (!data[parameterType][param.name]) {
        return {
          parameterType,
          field: param.name,
        };
      }
    }
  }

  const { bodyParams } = getBodyParams(apiEndpoint.operation);

  for (const param of bodyParams) {
    if (param.required === true) {
      if (!data["BODY"][param.name]) {
        return {
          parameterType: "BODY",
          field: param.name,
        };
      }
    }
  }
};

const Container = styled.div`
  height: 100vh;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  /* on screen sizes with the side nav bar, we must subtract
  the width of the side nav bar to get long query parameters not to cause
  overflow */
  width: calc(100% - 270px);
  position: relative;

  @media (max-width: ${sizes.medium}px) {
    height: calc(100vh - 41px); // minus height of nav bar
    width: 100%;
  }

  .Pane1 {
    overflow-y: scroll;
  }
`;

const MainContentContainer = ({
  apiName,
  apiEndpoint,
  apiMetadata,
  locationQueryParams,
  setResponse,
  isLoading,
  setIsLoading,
}: {
  apiName: ApiNameType;
  apiEndpoint: EndpointType;
  apiMetadata: ApiMetadataType;
  locationQueryParams: Record<string, string>;
  setResponse: (response: ResponseType) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
}) => {
  const operationId = apiEndpoint.operation.operationId as string;

  const serverVariables = apiMetadata?.servers?.[0]?.variables;

  // todo - set defaults here I think? insetad of in components...
  // though maybe not. todo: look up what "default" actually means in open api spec

  const [apiData, setApiData] = useLocalStorage<{
    [apiName: string]: { [operationId: string]: ParameterData };
  }>("api-endpoint-data", {});

  const data = apiData[apiName]?.[operationId] || DEFAULT_PARAMETER_DATA;

  const setData = (newData: ParameterData) => {
    setApiData({
      ...apiData,
      [apiName]: {
        ...(apiData[apiName] || {}),
        [operationId]: newData,
      },
    });
  };

  // Data that's shared across API endpints, like Authorization token
  const [sharedApiData, setSharedApiData] = useLocalStorage<{
    [apiName: string]: ParameterData;
  }>(`shared-api-data`, {
    // AUTHORIZATION: {},
    // HEADERS: {},
    // QUERY_PARAMS: {},
    // BODY: {},
    // PATH_PARAMS: {},
  });

  const sharedData = sharedApiData[apiName] || DEFAULT_PARAMETER_DATA;

  const setSharedData = (newSharedData: ParameterData) => {
    setSharedApiData({
      ...sharedApiData,
      [apiName]: newSharedData,
    });
  };

  const [showCodeModalOpen, setShowCodeModalOpen] = useState(false);

  const [validationError, setValidationError] = useState<
    ValidationErrorType | undefined
  >(undefined);

  const onSubmit = async (actionType: SendActionType) => {
    const _validationError = checkForValidationError(data, apiEndpoint);
    if (_validationError) {
      toast.warning(`${_validationError.field} is a required parameter`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      setValidationError(_validationError);
      return;
    }

    if (!apiEndpoint.operation.operationId) {
      throwError("Endpoint missing operationId");
      return;
    }
    setIsLoading(true);
    try {
      switch (actionType) {
        case "SEND": {
          const res = await executeRequest(
            apiName,
            apiEndpoint.operation.operationId,
            data,
            sharedData
          );
          setResponse(res);
          break;
        }
        case "COPY_CURL": {
          await copyCurl(
            apiName,
            apiEndpoint.operation.operationId,
            data,
            sharedData
          );
          break;
        }
        case "SHOW_CODE": {
          setShowCodeModalOpen(true);
          break;
        }
      }
    } catch (err) {
      if (err) {
        toast.warning(err, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      }
    }
    setIsLoading(false);
  };

  // Endpoint docs. Fallback to docs for the whole API
  const endpointDocs =
    apiEndpoint.operation.externalDocs?.url ||
    API_ADDITIONAL_METADATA[apiName].docsUrl;

  const prettyName = API_ADDITIONAL_METADATA[apiName].prettyName;

  const scopes =
    API_ADDITIONAL_METADATA[apiName].scopesForOperation?.(
      apiEndpoint.operation
    ) || [];

  const apiHasAuth = scopes === true || scopes.length > 0;

  const faqs = [
    ...(apiHasAuth
      ? [
          {
            question: `How do I authenticate to the ${prettyName} API?`,
            answer: `In the Authorization tab, press "Authorize" and follow the OAuth flow to let tryapis.com
      create an access token. Alternatively, you
      can set the Authorization header manually if you already have a token.`,
          },
        ]
      : []),
    {
      question: `How can I use this API endpoint in my app or website?`,
      answer: `First, add any parameters you want to pass in with the request. Then, click the dropdown arrow next to the "send" button and select "Generate code". Press the "Generate code" button and choose your programming language of choice, like Node.js or Python.`,
    },
    {
      question: `What's the difference between tryapis.com and Postman?`,
      answer: `tryapis.com pre-populates headers, query and body parameters (with in-line docs!) to save you time. Postman is better for internal APIs that don't have OpenAPI Specs.`,
    },
    {
      question: `What version of ${prettyName}'s API does tryapis.com use?`,
      answer: `${apiMetadata.info.version} (from ${prettyName}'s latest OpenAPI spec)`,
    },
    {
      question: `Do I have to pay to use tryapis.com?`,
      answer: `No, tryapis.com is free to use.`,
    },
  ];

  const isBrowser = () => typeof window !== "undefined";
  /**
   * Compute where the FAQs should show up
   */
  const windowHeight = isBrowser() ? window.innerHeight : 800;

  const mainContentBeforeFAQsRef = useRef();

  const mainContentBeforeFAQsRect =
    mainContentBeforeFAQsRef.current?.getBoundingClientRect();

  let marginToFAQs = null;

  if (mainContentBeforeFAQsRect) {
    const offset = mainContentBeforeFAQsRect.height;
    marginToFAQs = windowHeight - offset;
    // Margin should be at least 0
    marginToFAQs = marginToFAQs > 0 ? marginToFAQs : 0;
    // Account for navbar (when small window) and a small offset
    marginToFAQs += 54;
  }

  return (
    <MainContent>
      <div ref={mainContentBeforeFAQsRef}>
        <ContentHeader>
          <Link to={`/${apiName}`}>
            {API_ADDITIONAL_METADATA[apiName].logo}
          </Link>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ShareFeedback />

            <ViewDocs href={endpointDocs} target="_blank">
              <MediumTitleDarkGray style={{ marginRight: 6 }}>
                View docs
              </MediumTitleDarkGray>
              <OpenInNewTab />
            </ViewDocs>
          </div>
        </ContentHeader>
        <SendBar
          isLoading={isLoading}
          url={generateUrl(
            apiEndpoint.path,
            apiMetadata,
            data.PATH_PARAMS,
            sharedData.SERVER_VARIABLES
          )}
          method={apiEndpoint.method}
          onSubmit={onSubmit}
        />
        {/* todo - later, truncate output and let people expand */}
        <MediumTitleDarkGrayH1
          style={{ marginTop: 24, maxWidth: 750, marginLeft: 16 }}
        >
          <ReactMarkdown linkTarget="_blank" rehypePlugins={[rehypeRaw]}>
            {apiEndpoint.operation.description ||
              apiEndpoint.operation.summary ||
              apiEndpoint.operation.operationId ||
              ""}
          </ReactMarkdown>
        </MediumTitleDarkGrayH1>
        <div>
          <ParameterEditor
            apiName={apiName}
            validationError={validationError}
            data={data}
            setData={setData}
            sharedData={sharedData}
            setSharedData={setSharedData}
            operation={apiEndpoint.operation}
            clearValidationError={() => setValidationError(undefined)}
            locationQueryParams={locationQueryParams}
            serverVariables={serverVariables}
          />
        </div>
      </div>

      {marginToFAQs !== null && (
        <LinksContainer style={{ marginTop: marginToFAQs }}>
          {faqs.map((faq) => (
            <FAQ faq={faq} key={faq.question} />
          ))}
        </LinksContainer>
      )}

      {showCodeModalOpen && (
        <ShowCodeModal
          show={showCodeModalOpen}
          hide={() => setShowCodeModalOpen(false)}
          apiName={apiName}
          operationId={apiEndpoint.operation.operationId as string}
          data={data}
          sharedData={sharedData}
        />
      )}
    </MainContent>
  );
};

const DEFAULT_PARAMETER_DATA = {
  AUTHORIZATION: {},
  SERVER_VARIABLES: {},
  QUERY_PARAMS: {},
  PATH_PARAMS: {},
  BODY: {},
  HEADERS: {},
};

const Main = ({
  apiName,
  apiEndpoint,
  apiMetadata,
  locationQueryParams,
}: {
  apiName: ApiNameType;
  apiEndpoint: EndpointType;
  apiMetadata: ApiMetadataType;
  locationQueryParams: Record<string, string>;
}) => {
  // maybe also react context for this
  const [response, setResponse] = useState<ResponseType | undefined>();
  const operationId = apiEndpoint.operation.operationId as string;
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Container>
      <SplitPane split="horizontal" primary="second" defaultSize={300}>
        <Pane className="Pane1">
          <MainContentContainer
            apiName={apiName}
            apiEndpoint={apiEndpoint}
            apiMetadata={apiMetadata}
            locationQueryParams={locationQueryParams}
            setResponse={setResponse}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </Pane>
        {response && (
          <Pane>
            <ResultsDrawer
              response={response}
              isLoading={isLoading}
              downloadFileName={`${apiName}_${operationId}`}
            />
          </Pane>
        )}
      </SplitPane>
    </Container>
  );
};

type Props = {
  apiName: ApiNameType;
  apiEndpoint: EndpointType;
  // Spec without all the endpoints
  apiMetadata: ApiMetadataType;
  apiEndpointsSummary: EndpointSummaryType[];
};

const IndexPage = ({
  pageContext,
  location,
}: {
  pageContext: Props;
  location: any;
}) => {
  const {
    apiName,
    apiEndpoint,
    apiEndpointsSummary,

    apiMetadata,
  } = pageContext;

  const locationQueryParams = queryString.parse(location.search);

  const prettyName = API_ADDITIONAL_METADATA[apiName].prettyName;
  const description = `Instantly send requests to the ${prettyName} API. ${
    apiEndpoint.operation.description ||
    apiEndpoint.operation.summary ||
    apiEndpoint.operation.operationId
  }`;

  const title = `${prettyName} API | ${
    apiEndpoint.operation.summary ||
    apiEndpoint.operation.description ||
    apiEndpoint.operation.operationId
  }`;

  return (
    <div>
      <Layout
        apiMetadata={apiMetadata}
        apiEndpointsSummary={apiEndpointsSummary}
        apiName={apiName}
        seoTitle={title}
        seoDescription={description}
      >
        <EndpointsNavigation
          tags={apiMetadata.tags || []}
          endpointsSummary={apiEndpointsSummary}
          apiName={apiName}
          selectedOperationId={
            apiEndpoint.operation.operationId &&
            replaceAll(apiEndpoint.operation.operationId, "_", "-")
          }
          // TODO - we are assuming that endpoints have only one tag (or
          // else we'll open up the wrong folder)
          // Easy fix - add a #tag for which tag you've opened from the hamburger
          selectedOperationTag={apiEndpoint.operation.tags?.[0]}
        >
          <Main
            apiName={apiName}
            apiEndpoint={apiEndpoint}
            apiMetadata={apiMetadata}
            locationQueryParams={locationQueryParams as Record<string, string>}
          />
        </EndpointsNavigation>
      </Layout>
    </div>
  );
};

export default IndexPage;

import * as React from "react";
import styled from "styled-components";
import { OpenAPIV3 } from "openapi-types";

import { Button } from "antd";
import { COLORS } from "../utils/colors";
import { LargeTitleLightGray } from "./common";
import MethodTag from "./MethodTag";
import { Menu, Dropdown } from "antd";
import useLocalStorage from "../utils/useLocalStorage";
import { useHotkeysIncludingInInputs } from "../utils/utils";
import { DownArrow } from "./icons";

const SendBarContainer = styled.div`
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  background: ${COLORS.brightDarkGray};
  width: 100%;
  padding-left: 16px;
  margin-top: 44px;
`;

type SendBarProps = {
  isLoading: boolean;
  url: JSX.Element;
  method: OpenAPIV3.HttpMethods;
  onSubmit: (actionType: SendActionType) => void;
};

const SendOptionsContainer = styled.div`
  border-radius: 0px 6px 6px 0px;
  height: 46px;
  width: 40px;
  background: ${COLORS.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid ${COLORS.lightPurple};

  &:hover {
    background: ${COLORS.onHoverPurple};
  }
`;

const MenuItem = styled.div<{ selected: boolean }>`
  color: ${(props) => (props.selected ? COLORS.primary : COLORS.nearWhite)};

  &:hover {
    color: black;
  }
`;

export type SendActionType = "SEND" | "COPY_CURL" | "SHOW_CODE";

const SendBar = (props: SendBarProps) => {
  const [sendAction, setSendAction] = useLocalStorage<SendActionType>(
    "send-action",
    "SEND"
  );
  const buttonRef = React.useRef<any>(null);
  const handleMenuClick = (e: { key: string }) => {
    setSendAction(e.key as SendActionType);
  };

  const onSendInvoked = () => {
    buttonRef?.current?.blur();
    props.onSubmit(sendAction);
  };

  useHotkeysIncludingInInputs("cmd + enter", onSendInvoked);

  // todo - add shortcuts
  const menu = (
    <Menu
      onClick={handleMenuClick}
      style={{ background: COLORS.brightDarkGray, color: COLORS.nearWhite }}
    >
      <Menu.Item key="SEND">
        <MenuItem selected={sendAction === "SEND"}> Send request</MenuItem>
      </Menu.Item>
      <Menu.Item key="COPY_CURL">
        <MenuItem selected={sendAction === "COPY_CURL"}>
          Copy cURL request
        </MenuItem>
      </Menu.Item>
      <Menu.Item key="SHOW_CODE">
        <MenuItem selected={sendAction === "SHOW_CODE"}>Generate code</MenuItem>
      </Menu.Item>
    </Menu>
  );

  return (
    <SendBarContainer>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "8px 0px",
        }}
      >
        <div style={{ marginRight: 12 }}>
          <MethodTag method={props.method} size="large" />
        </div>
      </div>
      <LargeTitleLightGray
        style={{
          display: "flex",
          justifyContent: "flex-start",
          textOverflow: "ellipsis",
          overflow: "scroll",
          whiteSpace: "nowrap",
          width: "100%",
        }}
      >
        {props.url}
      </LargeTitleLightGray>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          flexShrink: 1,
          flexGrow: 0,
        }}
      >
        <Button
          ref={buttonRef}
          type="primary"
          size="large"
          style={{ height: 46, borderRadius: 0 }}
          onClick={onSendInvoked}
          loading={props.isLoading}
        >
          {sendAction === "SEND"
            ? "Send"
            : sendAction === "COPY_CURL"
            ? "Copy cURL"
            : "Generate code"}
        </Button>
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <SendOptionsContainer>
              <DownArrow />
            </SendOptionsContainer>
          </a>
        </Dropdown>
      </div>
    </SendBarContainer>
  );
};
export default SendBar;
